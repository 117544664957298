import { NAME as DesktopHeaderName, HeaderComponents } from '@spa-ec/components/Header/DesktopHeader.theme';
import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as ProductTableName } from '@spa-ec/components/ProductTable/ProductTable.theme';
import { ProductShelfCategoryKey } from '@spa-core/store/products/constants';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentWidth: '22%',
    sideContentPadding: 'px-4',
    linkColor: theme.col.vars.textLinkColor,
    linkColorHover: theme.col.secondary.peer,
};
const headerTheme = {
    showCountrySelector: false,
};
const desktopHeaderTheme = {
    headerComponents: [
        HeaderComponents.SITE_LOGO,
        HeaderComponents.CUSTOMER_TYPE_SELECTOR,
        HeaderComponents.PROMOTIONS,
        HeaderComponents.SEARCH,
        HeaderComponents.MY_ACCOUNT_AND_CART,
    ],
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.tertiary.peer,
    bg: 'white',
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const productTableTheme = {
    categoriesOrder: [
        ProductShelfCategoryKey.ONE_BUY_PRODUCTS,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.ORIGINAL,
        ProductShelfCategoryKey.UPSELLING,
        ProductShelfCategoryKey.FREE_CATEGORIES_LIST,
    ],
};
export default {
    [ContentPageName]: contentPageTheme,
    [HeaderName]: headerTheme,
    [DesktopHeaderName]: desktopHeaderTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [ProductTableName]: productTableTheme,
};
